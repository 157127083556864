import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IFetchUserData,
  ISendPhoneNumberConfirmationData,
} from '@shared/models/auth';

export const FETCH_CURRENT_USER: TypedDocumentNode<IFetchUserData, {}> = gql`
  query self {
    self {
      id
      firstName
      surname
      email
      phoneNumber
      roles
      confirmedAt
      isActive

      insertedAt
      preference {
        roles
        markets
        investmentStyles
        rehabComfortabilities
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET: TypedDocumentNode<
  {},
  { email: string }
> = gql`
  query requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const SEND_PHONE_NUMBER_CONFIRMATION: TypedDocumentNode<
  ISendPhoneNumberConfirmationData,
  {}
> = gql`
  query sendPhoneNumberConfirmation {
    sendPhoneNumberConfirmation
  }
`;
