import { createReducer, on } from '@ngrx/store';

import { IFilter, IFilterNumber } from '@shared/models/graphql';
import { IFilterProForma, IPropertiesFilters } from '@shared/models/filters';

import {
  resetFiltersAction,
  resetUnsavedFiltersAction,
  setFiltersAction,
  setUnsavedStateFiltersAction,
  setSavedStateFiltersAction,
  setUnsavedStatusFiltersAction,
  setUnsavedPriceFiltersAction,
  setUnsavedProFormaFiltersAction,
  setUnsavedBedroomsFiltersAction,
  setUnsavedBathroomsFiltersAction,
  setUnsavedSubtypeFiltersAction,
  setUnsavedSquareFootFiltersAction,
  setUnsavedLotSizeFiltersAction,
  setUnsavedYearBuiltFiltersAction,
} from './filters.actions';
import { TPropertySubType } from '@shared/models/properties';

export interface FiltersState {
  current?: IPropertiesFilters;
  unsaved?: IPropertiesFilters;
}

export const initialState: FiltersState = {
  current: {
    state: undefined,
    status: { in: ['OPEN', 'PENDING'] },
    price: undefined,
    proForma: undefined,
    bedrooms: undefined,
    bathrooms: undefined,
    subType: undefined,
    squareFoot: undefined,
    lotSize: undefined,
    yearBuilt: undefined,
  },
  unsaved: {
    state: undefined,
    status: undefined,
    price: undefined,
    proForma: undefined,
    bedrooms: undefined,
    bathrooms: undefined,
    subType: undefined,
    squareFoot: undefined,
    lotSize: undefined,
    yearBuilt: undefined,
  },
};

export const filtersReducer = createReducer(
  initialState,
  on(resetFiltersAction, (state) => ({
    ...initialState,
    current: {
      ...initialState.current,
    },
    unsaved: {
      ...initialState.unsaved,
    },
  })),
  on(resetUnsavedFiltersAction, (state) => ({
    ...state,
    unsaved: { ...state.current },
  })),
  on(setFiltersAction, (state) => ({
    ...state,
    current: { ...state.unsaved },
  })),
  on(setUnsavedStateFiltersAction, (state, action) => ({
    ...state,
    unsaved: {
      ...state.unsaved,
      state: action.in ? { in: [...action.in] } : undefined,
    },
  })),
  on(setSavedStateFiltersAction, (state, action) => ({
    ...state,
    current: {
      ...state.current,
      state: action.in ? { in: [...action.in] } : undefined,
    },
  })),
  on(setUnsavedStatusFiltersAction, (state, action) => ({
    ...state,
    unsaved: {
      ...state.unsaved,
      status: action.in ? { in: [...action.in] } : undefined,
    },
  })),
  on(setUnsavedPriceFiltersAction, (state, action) => {
    const query: IFilterNumber & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, price: { ...query } },
    };
  }),
  on(setUnsavedProFormaFiltersAction, (state, action) => {
    const query: IFilterProForma & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, proForma: { ...query } },
    };
  }),
  on(setUnsavedBedroomsFiltersAction, (state, action) => {
    const query: IFilterNumber & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, bedrooms: { ...query } },
    };
  }),
  on(setUnsavedBathroomsFiltersAction, (state, action) => {
    const query: IFilterNumber & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, bathrooms: { ...query } },
    };
  }),
  on(setUnsavedSubtypeFiltersAction, (state, action) => {
    const query: IFilter<TPropertySubType> & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, subType: { ...query } },
    };
  }),
  on(setUnsavedSquareFootFiltersAction, (state, action) => {
    const query: IFilterNumber & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, squareFoot: { ...query } },
    };
  }),
  on(setUnsavedLotSizeFiltersAction, (state, action) => {
    const query: IFilterNumber & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, lotSize: { ...query } },
    };
  }),
  on(setUnsavedYearBuiltFiltersAction, (state, action) => {
    const query: IFilterNumber & { type?: string } = { ...action };
    delete query.type;
    return {
      ...state,
      unsaved: { ...state.unsaved, yearBuilt: { ...query } },
    };
  })
);
