import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { PropertiesState } from './properties.reducer';

export const selectProperties = createSelector(
  (state: AppState) => state.properties,
  (properties: PropertiesState) => properties
);

export const selectPropertiesLoading = createSelector(
  selectProperties,
  (state: PropertiesState) => state.loading
);

export const selectPropertiesCount = createSelector(
  selectProperties,
  (state: PropertiesState) => state.pageOfProperties?.count
);

export const selectPropertiesResults = createSelector(
  selectProperties,
  (state: PropertiesState) => state.pageOfProperties?.results
);

export const selectPropertiesErrors = createSelector(
  selectProperties,
  (state: PropertiesState) => state.errors
);

export const selectPropertyItem = createSelector(
  selectProperties,
  (state: PropertiesState) => state.item
);

export const selectPropertyItemLoading = createSelector(
  selectPropertyItem,
  (state) => state?.loading
);

export const selectPropertyItemProperty = createSelector(
  selectPropertyItem,
  (state) => state?.property
);

export const selectProForma = createSelector(
  selectProperties,
  (state: PropertiesState) => state.proForma
);
