<div *ngIf="user" class="wrapper" [matMenuTriggerFor]="userMenu">
  <div class="name">
    Hi, {{ user.firstName }}
    <app-shared-icon
      name="caret-down"
      [size]="10"
      class="icon"
    ></app-shared-icon>
  </div>
  <img src="/assets/images/user.png" width="35" alt="user" />
</div>

<mat-menu #userMenu="matMenu">
  <a routerLink="/profile/offers">My offers</a>
  <a routerLink="/profile/favorites" *ngIf="favoritesFeatureFlag$ | async"
    >My favorites</a
  >
  <a routerLink="/profile/account">My profile</a>
  <a routerLink="/buy-boxes" *ngIf="buyBoxesFeatureFlag$ | async"
    >My buy boxes</a
  >
  <a routerLink="/logout">Logout</a>
</mat-menu>

<app-shared-button
  *ngIf="!user && !homeScreen"
  size="small"
  (click)="showSignInModal()"
>
  Join Rebuilt
</app-shared-button>
