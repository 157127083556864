import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IPageOfBuyBoxesData,
  IPageOfBuyBoxesInput,
} from '@shared/models/buy-boxes';

export const BUY_BOX_FRAGMENT = gql`
  fragment BuyBoxFragment on BuyBox {
    id
    name
    subTypes
    price {
      lower
      upper
    }
    beds {
      lower
      lowerInclusive
      upper
      upperInclusive
    }
    baths {
      lower
      lowerInclusive
      upper
      upperInclusive
    }
    sqft {
      lower
      lowerInclusive
      upper
      upperInclusive
    }
    yearBuilt {
      lower
      lowerInclusive
      upper
      upperInclusive
    }
  }
`;

export const LIST_OWN_BUY_BOXES: TypedDocumentNode<
  IPageOfBuyBoxesData,
  IPageOfBuyBoxesInput
> = gql`
  ${BUY_BOX_FRAGMENT}
  query listOwnBuyBoxes($limit: Int, $sort: [BuyBoxSortInput]) {
    listOwnBuyBoxes(limit: $limit, sort: $sort) {
      count
      results {
        ...BuyBoxFragment
      }
    }
  }
`;

export const FETCH_BUY_BOX: TypedDocumentNode<
  IPageOfBuyBoxesData,
  { id: string }
> = gql`
  ${BUY_BOX_FRAGMENT}
  query listOwnBuyBox($id: BuyBoxFilterId) {
    listOwnBuyBoxes(limit: 1, filter: { id: { eq: $id } }) {
      count
      results {
        ...BuyBoxFragment
      }
    }
  }
`;
