import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { translateInputError } from '@core/utils/errors';
import { TIconName } from '@shared/models/icons';

@Component({
  selector: 'app-shared-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnChanges {
  @Input() for: string = '';
  @Input() label: string = '';

  @Input() selector?: boolean = false;
  @Input() theme: 'white' | 'dark' = 'white';
  @Input() prefix?: string;
  @Input() icon?: TIconName;

  @Input() value?: string | number = '';
  @Input() focused: boolean = false;
  @Input() disabled: boolean = false;

  @Input() touched?: boolean = false;
  @Input() errors?: ValidationErrors | null;
  @Input() patternError?: string;

  @Input() type?: 'password';
  @Output() toggleVisibility = new EventEmitter<'text' | 'password'>();

  public passwordVisibility: 'text' | 'password' = 'password';
  protected errorMessage?: string;

  ngOnChanges(): void {
    if (this.touched && this.errors) {
      this.errorMessage = translateInputError(this.errors, this.patternError);
    }
  }

  hangleToggleVisibility(): void {
    this.passwordVisibility =
      this.passwordVisibility === 'text' ? 'password' : 'text';
    this.toggleVisibility.emit(this.passwordVisibility);
  }
}
