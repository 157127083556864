import { Component, Input } from '@angular/core';

import { TIconName } from '@shared/models/icons';

@Component({
  selector: 'app-shared-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() size: number = 20;
  @Input() name?: TIconName;

  getIconPath(): string {
    return `assets/icons/${this.name}.svg`;
  }
}
