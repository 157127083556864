<div
  class="wrapper"
  [ngClass]="{
    'theme-dark': theme === 'dark',
    error: errors && touched,
    'with-prefix': !!prefix,
    'with-label': !!label,
    selector,
    disabled
  }"
>
  <label
    *ngIf="label"
    [for]="for"
    class="global-input-label"
    [ngClass]="{ active: focused || !!value || value === 0 || !!prefix }"
    >{{ label }}</label
  >

  <span *ngIf="prefix" class="prefix" [ngClass]="{ 'with-label': label }">{{
    prefix
  }}</span>

  <ng-content></ng-content>

  <div *ngIf="errors && errorMessage && touched" class="input-error-message">
    {{ errorMessage }}
  </div>

  <app-shared-icon
    *ngIf="type === 'password'"
    name="eye"
    [size]="20"
    class="toggle-visibility"
    (click)="hangleToggleVisibility()"
  ></app-shared-icon>

  <app-shared-icon
    class="selector-icon"
    name="chevron-down-2"
    [size]="18"
    *ngIf="selector"
  ></app-shared-icon>

  <app-shared-icon
    class="selector-icon"
    *ngIf="icon"
    [name]="icon"
    [size]="18"
  ></app-shared-icon>
</div>
