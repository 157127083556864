import { createReducer, on } from '@ngrx/store';

import { StringPipe } from '@shared/pipes';
import { IPropertyProForma } from '@shared/models/pro-forma';
import { IPageOfProperty, IProperty } from '@shared/models/properties';

import {
  resetPropertiesAction,
  fetchPropertiesAction,
  fetchPropertiesActionSuccess,
  fetchPropertiesActionFailure,
  fetchPropertyAction,
  fetchPropertyActionSuccess,
  fetchPropertyActionFailure,
  clearPropertyAction,
  fetchPropertyOffer,
  fetchPropertyProFormaAction,
  fetchPropertyProFormaSuccess,
  fetchPropertyProFormaFailure,
  clearPropertyProFormaAction,
} from './properties.actions';

export interface PropertiesState {
  loading: boolean;
  errors: boolean;
  pageOfProperties?: IPageOfProperty;
  item?: {
    loading: boolean;
    errors: boolean;
    property?: IProperty;
  };
  proForma?: {
    loading: boolean;
    errors: boolean;
    data?: IPropertyProForma;
  };
}

export const initialState: PropertiesState = {
  loading: true,
  errors: false,
  pageOfProperties: undefined,
  item: undefined,
  proForma: undefined,
};

export const propertiesReducer = createReducer(
  initialState,
  on(resetPropertiesAction, () => ({ ...initialState })),
  on(fetchPropertiesAction, (state) => ({
    ...state,
    loading: true,
    errors: false,
    pageOfProperties: state.pageOfProperties?.count
      ? state.pageOfProperties
      : undefined,
  })),
  on(fetchPropertiesActionSuccess, (state, action) => ({
    ...state,
    loading: false,
    errors: false,
    pageOfProperties: {
      ...action,
      results: [
        ...action.results.map((property) => ({
          ...property,
          price: new StringPipe().transform(
            property.price,
            'strPriceToDecimal'
          ),
        })),
      ],
    },
  })),
  on(fetchPropertiesActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: true,
    pageOfProperties: undefined,
  })),
  on(fetchPropertyAction, (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      errors: false,
    },
  })),
  on(fetchPropertyActionSuccess, (state, action) => ({
    ...state,
    item: {
      loading: false,
      errors: false,
      property: {
        ...action.property,
        price: new StringPipe().transform(
          action.property.price,
          'strPriceToDecimal'
        ),
      },
    },
  })),
  on(fetchPropertyActionFailure, (state) => ({
    ...state,
    item: {
      loading: true,
      errors: true,
      property: undefined,
    },
  })),
  on(clearPropertyAction, (state) => ({
    ...state,
    item: undefined,
  })),
  on(fetchPropertyOffer, (state, action) => ({
    ...state,
    item: {
      loading: false,
      errors: false,
      property: state.item?.property
        ? {
            ...state.item.property,
            offerorCurrentOffer: action,
          }
        : undefined,
    },
  })),
  on(fetchPropertyProFormaAction, (state, action) => ({
    ...state,
    proForma: {
      loading: true,
      errors: false,
      data: undefined,
    },
  })),
  on(fetchPropertyProFormaSuccess, (state, action) => ({
    ...state,
    proForma: {
      loading: false,
      errors: false,
      data: { ...action },
    },
  })),
  on(fetchPropertyProFormaFailure, (state, action) => ({
    ...state,
    proForma: {
      loading: false,
      errors: true,
      data: undefined,
    },
  })),
  on(clearPropertyProFormaAction, (state) => ({
    ...state,
    proForma: undefined,
  }))
);
