<div class="wrapper" [ngClass]="{ open }">
  <div class="container">
    <header>
      <div class="overlay">
        <ng-container *ngIf="user">
          <div class="sign-out">
            <a routerLink="/logout" (click)="closeMenuHandler()">
              Log out
              <app-shared-icon
                name="log-out-white"
                class="icon"
                [size]="15"
              ></app-shared-icon>
            </a>
          </div>
          <a routerLink="/profile/account" (click)="closeMenuHandler()">
            <img src="/assets/images/user.png" alt="user icon" />
          </a>
          <a
            *ngIf="user"
            routerLink="/profile/account"
            (click)="closeMenuHandler()"
          >
            <div class="name">{{ user.firstName }} {{ user.surname }}</div>
            <div class="email">{{ user.email }}</div>
          </a>
        </ng-container>
        <div class="sign-in" *ngIf="!user">
          <div class="link" (click)="showSignInModal()">
            <app-shared-icon
              name="user-white"
              class="icon"
              [size]="20"
            ></app-shared-icon>
            Sign in
          </div>
        </div>
      </div>
    </header>

    <ul>
      <li>
        <a routerLink="/" (click)="closeMenuHandler()">Home</a>
      </li>
      <ng-container *ngIf="user">
        <li>
          <a routerLink="/profile/offers" (click)="closeMenuHandler()">
            My offers</a
          >
        </li>
        <li *ngIf="favoritesFeatureFlag$ | async">
          <a routerLink="/profile/favorites" (click)="closeMenuHandler()">
            My favorites
          </a>
        </li>
        <li *ngIf="buyBoxesFeatureFlag$ | async">
          <a routerLink="/buy-boxes" (click)="closeMenuHandler()">
            My buy boxes
          </a>
        </li>
        <li>
          <a routerLink="/profile/account" (click)="closeMenuHandler()">
            My profile
          </a>
        </li>
        <hr />
      </ng-container>
      <li *ngFor="let item of links; let i = index; trackBy: trackByFn">
        <a
          [href]="item.path"
          target="_blank"
          (click)="toggleSubmenu($event, i)"
        >
          {{ item.title }}
          <app-shared-icon
            *ngIf="item.children"
            [name]="showSubmenu === i ? 'caret-right' : 'chevron-down'"
            [size]="10"
            class="icon"
          ></app-shared-icon
        ></a>
        <ul *ngIf="item.children" [ngClass]="{ open: showSubmenu === i }">
          <li *ngFor="let subItem of item.children; trackBy: trackByFn">
            <a [href]="subItem.path" target="_blank">
              {{ subItem.title }}
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <footer>
      &copy; {{ year }}
      <a href="https://rebuilt.com" target="_blank">Rebuilt</a> - All rights
      reserved.
    </footer>
  </div>
</div>
