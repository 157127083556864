import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IPlaceOfferInput,
  IPlaceOfferResult,
  IWithdrawOfferResult,
} from '@shared/models/offers';

import { MUTATION_ERROR_FRAGMENT } from '../errors.fragments';
import { OFFER_FRAGMENT } from '../queries/offers.queries';

export const PLACE_OFFER: TypedDocumentNode<
  IPlaceOfferResult,
  IPlaceOfferInput
> = gql`
  ${OFFER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation placeOffer($input: PlaceOfferInput) {
    placeOffer(input: $input) {
      result {
        ...OfferFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const WITHDRAW_OFFER: TypedDocumentNode<
  IWithdrawOfferResult,
  { id: string }
> = gql`
  ${OFFER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation withdrawOffer($id: ID!) {
    withdrawOffer(id: $id) {
      result {
        ...OfferFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;
