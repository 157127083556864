<ng-container *ngIf="modalWrapper; else noModalWrapper">
  <app-shared-modal
    size="small"
    [open]="!alwaysHide && !!modal && (!homeScreen || isMobile)"
    (modalClose)="onCloseHandler()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-shared-modal>
</ng-container>

<ng-template #noModalWrapper>
  <div>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <div class="wrapper">
    <app-auth-modal-header [slug]="modal"></app-auth-modal-header>

    <app-auth-signin *ngIf="modal === 'signin'"></app-auth-signin>

    <app-auth-signup *ngIf="modal === 'signup'"></app-auth-signup>

    <app-auth-verify-identity
      *ngIf="modal === 'verify-identity'"
    ></app-auth-verify-identity>

    <app-auth-account-setup
      *ngIf="modal === 'account-setup'"
    ></app-auth-account-setup>

    <app-auth-welcome-user
      *ngIf="modal === 'welcome-user'"
    ></app-auth-welcome-user>

    <app-forgot-password
      *ngIf="modal === 'forgot-password'"
    ></app-forgot-password>

    <app-reset-password *ngIf="modal === 'reset-password'"></app-reset-password>

    <app-reset-password-sent
      *ngIf="modal === 'reset-password-sent'"
    ></app-reset-password-sent>
  </div>
</ng-template>
